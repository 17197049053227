.App {
  text-align: center;
  background-color: 'black';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.singleHotelContainer{
  width:45%
}

.singleReviewContainer{
  width:80%
}

.hotelImage{
  height: 25rem;
}

.introMainText{
  width:50%;
  font-size: 20px;
}

.introSubText{
  width:70%;
  font-size: 1.5rem;
}

.reviewImageBox{
  width:15%
}
.reviewTextBox{
  width:55%
}
.reviewProgressBox{
  width:5%
}
.reviewButtonBox{
  width:20%;
  margin-left: 15px;
}
.reviewText{
  font-size: 1.3rem !important;
}
.aboutText{
  font-size: 1.2rem !important;
}
.hotelLogo{
  height: 12rem;
  width: 100%;
  object-fit: fill;
}
@media (max-width: 1150px) {
  .singleHotelContainer{
    width:95%
  }
  .hotelImage{
    height: 13rem;
  }
  .introMainText{
    width:100%;
    font-size: 25px;
  }
  
  .introSubText{
    width:100%;
    font-size: 1.1rem;
  }

  .reviewImageBox{
    width:100%;
  }
  .reviewTextBox{
    width:100%
  }
  .reviewProgressBox{
    width:100%
  }
  .reviewButtonBox{
    width:100%
  }
  .reviewText{
  font-size: 1.0rem !important;
  }
  .hotelLogo{
    height:8rem;
    width:50%;
    object-fit:fill;
  }
  .aboutText{
    font-size: 1.0rem !important;
  }

}